<template>
  <v-card>
    <v-card-title secondary-title class="text-h6"
      >Benutzer wechseln</v-card-title
    >
    <v-card-text>
      <v-autocomplete
        v-model="selectedId"
        :items="sortedAccounts"
        item-value="id"
        item-text="name"
      ></v-autocomplete>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <div v-else style="margin: 18px"></div>
      <v-btn text outlined block class="mb-2" color="primary" @click="reset"
        >Zurücksetzten</v-btn
      >
      <v-btn text outlined block class="mb-2" color="warning" @click="change"
        >Benutzer wechseln</v-btn
      >
      <v-btn text outlined block class="mb-2" @click="$emit('close')"
        >Abbrechen</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia'
import axios from '@/plugins/axios'
import { useUserStore } from '@/stores/user'
import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'UserSwitchDialog',
  components: {},
  props: {},
  data() {
    return {
      selectedId: 0,
      loading: false,
    }
  },
  computed: {
    realUser() { return JSON.parse(localStorage.getItem('realUser')) },
    ...mapState(useUserStore, { user: store => store }),
    ...mapState(useAccountStore, { sortedAccounts: 'list' }),
  },
  methods: {
    reset() {
      this.loading = true
      this.loginAs(this.realUser.token)
    },
    change() {
      this.loading = true
      useAuthStore().tokenLogin(this.realUser.token)
        .then(() => axios.get(`/api/v1/auth/impersonate/${this.selectedId}`))
        .then(response => this.loginAs(response.data.token))
    },
    loginAs(token) {
      useAuthStore().tokenLogin(token)
        .then(() => useUserStore().fetchUser())
        .then(() => {
          this.loading = false
          this.$emit('close')
        })
    },
  },
  mounted() {
    useAccountStore().fetchAccounts()
    this.selectedId = this.user.id
  },
}
</script>

